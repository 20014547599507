<template>
  <div>
    <div style="display: inline-block; width: 100%; height: 100%;">
      <div class="child-top">
        <!--                地址设置-->
        <div class="input-from">
          <el-cascader
            :options="options"
            :props="{ checkStrictly: true }"
            @change="handleChange"
            clearable
            placeholder="请选择国家/省份/市区"
            style="float: left; margin-right: 10px; width: 150px;"
            v-model="selectedOptions"
          ></el-cascader>
          <el-input
            @keyup.enter.native="getData(true)"
            clearable
            placeholder="请输入详细地址"
            style="width: 200px; float: left;"
            v-model="input4"
          >
            <el-button
              @click="getData(true)"
              icon="iconfont iconsousuo"
              slot="append"
            ></el-button>
          </el-input>
        </div>
        <div class="operation-button">
          <!--                    :disabled="$store.getters.permissionsStr('增')"-->
          <el-button
            @click="addDataLog"
            :disabled="$store.getters.permissionsStr('新增')"
            class="brotherBtns"
            style="border-right: 0;"
          >
            <i class="iconfont icon-xinjian-icon" style="color: #4bb3ff;"></i>
            <span>新增</span>
          </el-button>
          <!--                    :class="$store.getters.permissionsStr('删除') ? 'disabledRed' : 'noDisabledRed'"-->
          <el-button
            :disabled="
              tableDataSelectArr.length === 0 ||
                $store.getters.permissionsStr('删除')
            "
            @click="delMoreData"
            class="brotherBtns"
          >
            <i
              class="iconfont icon-shanchu-icon iconFontSize"
              style="color: #ff6666;"
            ></i>
            <span>删除</span>
          </el-button>
        </div>
      </div>
      <!--            表格-->
      <div class="content-flex-pages">
        <el-table
          :data="tableData"
          @selection-change="tableSelectionChange"
          class="content-test-table"
          style="width: 100%;"
          v-loading="tableLoading"
        >
          <el-table-column type="selection" width="45"></el-table-column>
          <el-table-column label="序号" type="index" width="45">
            <template slot-scope="scope">
              {{ (currentPage - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="国家" prop="country_id" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLabel(scope.row.country_id).label }}
            </template>
          </el-table-column>
          <el-table-column
            label="省市"
            prop="province_id"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ getLabel(scope.row.province_id).label }}
            </template>
          </el-table-column>
          <el-table-column label="市区" prop="city_id" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ getLabel(scope.row.city_id).label }}
            </template>
          </el-table-column>
          <el-table-column
            label="详细地址"
            prop="address"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="顺序"
            prop="order_no"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="备注"
            prop="demo"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="状态"
            prop="status"
            show-overflow-tooltip
            width="80"
          >
            <template slot-scope="scope">
              <el-switch
                active-color="#0055E9"
                :active-value="1"
                :inactive-value="0"
                :disabled="$store.getters.permissionsStr('启/停用')"
                :width="35"
                @change="ChangeState(scope.row)"
                inactive-color="#ccc"
                v-model="scope.row.status"
              ></el-switch>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button
                v-show="!$store.getters.permissionsStr('编辑')"
                @click="editorData(scope.row, scope.$index)"
                size="medium"
                style="color: #0055E9; margin-right: 20px;"
                type="text"
                >编辑
              </el-button>
              <el-button
                @click="delTableData(scope.row)"
                v-show="!$store.getters.permissionsStr('删除')"
                class="redColor"
                size="medium"
                style="color: #FD3258; margin-right: 20px;"
                type="text"
                >删除
              </el-button>
              <el-button
                :class="scope.row.default_flag === 1 ? 'black' : 'greyColor'"
                :disabled="scope.row.default_flag === 0"
                @click="defaultSite(scope.row, scope.$index)"
                size="medium"
                type="text"
                >默认地址
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="10"
          :total="tableTotal"
          @current-change="handleCurrentChange"
          class="table-pagination"
          layout="total, prev, pager, next , jumper"
        ></el-pagination>
      </div>
      <!--            新增彈窗-->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="dialogTableVisible"
        title=""
        width="30%"
      >
        <div class="ModifyDoalog">
          <p>{{ !dialogTableVisibleType ? '新增' : '编辑' }}地址</p>
          <el-form
            :label-position="'left'"
            :model="form"
            :rules="rules"
            label-width="80px"
            ref="form"
          >
            <el-form-item label="国家" prop="country_id">
              <el-select
                @change="selectProvinceFun"
                placeholder="请选择国家"
                v-model="form.country_id"
              >
                <el-option
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                  v-for="item in options"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="省市" prop="province_id">
              <el-select
                @change="selectCityFun"
                placeholder="请选择省市"
                v-model="form.province_id"
              >
                <el-option
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                  v-for="item in cityList"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市区" prop="country_id">
              <el-select
                @change="selectAreaFun($event, options)"
                placeholder="请选择市区"
                v-model="form.city_id"
              >
                <el-option
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                  v-for="item in areaList"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input v-model="form.address"></el-input>
            </el-form-item>
            <el-form-item label="顺序" prop="order_no">
              <el-input v-model="form.order_no"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="demo">
              <el-input v-model="form.demo"></el-input>
            </el-form-item>
            <el-form-item label="设为默认">
              <el-radio :label="1" v-model="form.default_flag">是</el-radio>
              <el-radio :label="0" v-model="form.default_flag">否</el-radio>
            </el-form-item>
            <div class="form-bottom-button">
              <el-button @click.native="save" type="primary" v-no-more-click
                >保存</el-button
              >
              <el-button @click="dialogTableVisible = false">取消</el-button>
            </div>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
let data = {
  country_id: '', //国家
  province_id: '', //省
  city_id: '', //城市
  address: '', //详细地址
  order_no: '', //顺序
  demo: '', //备注
  default_flag: 1, //设置默认地址
};

export default {
  data() {
    return {
      // 被选中的市数据
      cityList: [],
      // 被选中的县数据
      areaList: [],
      dialogTableVisible: false, // 新增弹窗
      currentPage: 1,
      tableTotal: 0,
      dialogTableVisibleType: false, //是否是新增
      tableLoading: false,
      form: {
        ...data,
      },
      rules: {
        // country_id: this.$rules.country_id,
        country_id: { ...this.$rules.noEmpty, message: '国家不能为空' },
        province_id: { ...this.$rules.noEmpty, message: '省不能为空' },
        city_id: { ...this.$rules.noEmpty, message: '城市不能为空' },
        address: { ...this.$rules.noEmpty, message: '详细地址不能为空' },
      },
      country_Arr: [],
      province_Arr: [],
      city_Arr: [],
      tableData: [],
      stateId: [], //选中国家id
      status: '', //启动状态的值
      options: [], //三级联动所需数据
      selectedOptions: [], //这个是级联选择器中所需要的默认值是数组型 绑定值
      selectArr: {
        type: '',
        project: '',
        jobs: '',
        sex: '',
        inputType: '',
        grade: '',
      },
      input4: '',
      addUserDialog: false,

      ruleForm: {
        ...data,
      },
      tableDataSelectArr: [],
    };
  },
  created() {
    this.getState();
    this.getData();
  },
  watch: {
    // $route(to, from) {
    //     if (this.$route.query.uuid && this.sportType) {
    //         this.currentPage = 1;
    //         this.getData();
    //     }
    // }
  },
  mounted() {},
  methods: {
    getLabel(id) {
      let data = {};
      this.options.forEach((i, k) => {
        if (i.id === id) {
          data = i;
        } else if (i.children) {
          i.children.forEach((j, k1) => {
            if (j.id === id) {
              data = j;
            } else if (j.children) {
              j.children.forEach((p, k2) => {
                if (p.id === id) {
                  data = p;
                }
              });
            }
          });
        }
      });
      return data;
    },
    getData(e) {
      this.tableLoading = true;
      this.$axios
        .post(
          '/p/address/lists',
          this.$qs({
            page: e === true ? '' : this.currentPage,
            country_id: this.stateId[0],
            province_id: this.stateId[1],
            city_id: this.stateId[2],
            address: this.input4,
          })
        )
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.lists;
            this.tableTotal = res.data.data.total;
            this.tableLoading = false;
          }
        });
      if (e) {
        this.currentPage = 1;
      }
    },
    //获取国家
    getState() {
      this.$axios.post('/p/city/all', this.$qs({})).then((res) => {
        if (res.data.code === 0) {
          this.options = res.data.data;
        }
      });
    },
    //默认地址
    defaultSite(row) {
      this.$axios
        .post('/p/address/setDefault', this.$qs({ uuid: row.uuid }))
        .then((res) => {
          if (res.data.code === 0) {
            this.$utils.message();
          } else {
            this.$utils.message({
              type: 'error',
              message: res.data.message,
            });
          }
        });
    },
    //编辑
    editorData(row, index) {
      this.$axios
        .post('/p/address/read', this.$qs({ uuid: row.uuid }))
        .then((res) => {
          if (res.data.code === 0) {
            this.form = res.data.data;
            console.log(this.form);
            this.options.forEach((i, k) => {
              if (i.id === this.form.country_id) {
                this.cityList = i.children;
                i.children.forEach((j, k1) => {
                  if (j.id === this.form.province_id) {
                    this.areaList = j.children;
                  }
                });
              }
            });
          }
        });
      this.dialogTableVisibleType = true;
      this.dialogTableVisible = true;
      setTimeout(() => {
        this.$dialogHeight();
        this.$refs['form'].clearValidate();
      }, 100);
    },
    handleChange(value) {
      console.log(value);
      this.stateId = value;
    },
    /*表格选中*/
    tableSelectionChange(val) {
      this.tableDataSelectArr = val;
    },
    /*表格多行删除*/
    delMoreData() {
      let arr = [];
      this.tableDataSelectArr.forEach((i, k) => {
        arr.push(i.uuid);
      });
      this.dleFunc({ uuids: arr.join(',') });
    },
    /*表格单行删除*/
    delTableData(row) {
      this.dleFunc({ uuids: row.uuid });
    },
    dleFunc(data) {
      this.$confirm('确定要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(() => {
        this.$axios
          .post('p/address/del', this.$qs(data))
          .then((res) => {
            if (res.data.code === 0) {
              // 解决跳页后删除本页最后一条数据查询不跳转问题
              if (this.currentPage * 10 - 9 === this.total) {
                this.currentPage -= 1;
              }
              if (this.currentPage === 0) {
                this.currentPage = 1;
              }
              this.$utils.message();
              this.getData();
            } else {
              this.$utils.message({
                type: 'error',
                message: res.data.message,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    //改变状态
    ChangeState(row) {
      this.tableLoading = true;
      this.$axios
        .post(
          '/p/address/able',
          this.$qs({
            uuid: row.uuid,
          })
        )
        .then((response) => {
          if (response.data.code === 0) {
            this.status = row.status;
            this.$message({
              message: response.data.message,
              type: 'success',
            });
            this.getData();
            this.tableLoading = false;
          } else {
            this.$message({
              message: response.data.message,
              type: 'error',
            });
          }
        });
    },
    //新增
    addDataLog() {
      this.dialogTableVisibleType = false;
      this.dialogTableVisible = true;
      this.form = {
        cn_name: '', //姓名
        demo: '', //备注
        order_no: '', //顺序
        default_flag: 1,
      };
      setTimeout(() => {
        this.$dialogHeight();
        this.$refs['form'].clearValidate();
      }, 100);
    },
    //新增的保存
    save() {
      let submit = () => {
        //编辑
        let arr = [];
        // this.roleData.options.forEach((i,k)=>{
        //     if(i.model){
        //         arr.push(i.uuid)
        //     }
        // })
        console.log(this.form);
        if (this.dialogTableVisibleType) {
          delete this.form.role_name;
          let data = {
            uuid: this.form.uuid,
            country_id: this.form.country_id, //国家
            province_id: this.form.province_id, //省
            city_id: this.form.city_id, //城市
            address: this.form.address, //详细地址
            order_no: this.form.order_no, //顺序
            demo: this.form.demo, //备注
            default_flag: this.form.default_flag, //设置默认地址
          };
          for (let i in data) {
            if (data[i] === '') {
              delete data[i];
            }
          }
          this.$axios.post('/p/address/update', this.$qs(data)).then((res) => {
            if (res.data.code === 0) {
              this.getData();
              this.dialogTableVisible = false;
              this.$utils.message();
            } else {
              this.$utils.message({
                type: 'error',
                message: res.data.message,
              });
            }
          });
          //新增
        } else {
          console.log(this.form);
          let data = {
            country_id: this.form.country_id, //国家
            province_id: this.form.province_id, //省
            city_id: this.form.city_id, //城市
            address: this.form.address, //详细地址
            order_no: this.form.order_no, //顺序
            demo: this.form.demo, //备注
            default_flag: this.form.default_flag, //设置默认地址
          };
          this.$axios.post('/p/address/add', this.$qs(data)).then((res) => {
            if (res.data.code === 0) {
              this.getData();
              this.dialogTableVisible = false;
              this.$utils.message();
            } else {
              this.$utils.message({
                type: 'error',
                message: res.data.message,
              });
            }
          });
        }
      };
      this.$refs['form'].validate((valid) => {
        if (valid) {
          submit();
        } else {
          return false;
        }
      });
    },
    // 省份 国
    selectProvinceFun(event) {
      let arr = [];
      this.options.forEach((i, k) => {
        if (i.id === event) {
          arr = i.children;
        }
      });
      this.cityList = arr;
      // this.$emit('getLawyerListInfo', [event.label, 'province'])
    },
    // 省份 市
    selectCityFun(event) {
      this.form = {
        ...this.form,
        city_id: '',
      };
      let arr = [];
      this.cityList.forEach((i, k) => {
        if (i.id === event) {
          arr = i.children;
        }
      });
      this.areaList = arr;
    },
    // 省份 区
    selectAreaFun(event, item) {
      console.log(event);
    },
    //  页码
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.getData();
      this.tableLoading = true;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getData();
      this.tableLoading = true;
    },
    //导出
    // expor() {
    //     const numberName = this.selectArr.inputType == '1' ? '&personnel_umber=' + this.input4 : this.selectArr.inputType == '2' ? "&name=" + this.input4 : '';
    //     window.open(this.$store.state.url + '/p/staff/export/?department_uuid=' + this.$route.query.uuid + '&sex=' + this.selectArr.sex
    //         + '&sport_uuid=' + this.selectArr.type + '&sport_event_uuid=' + this.selectArr.project + '&station_uuid=' + this.selectArr.jobs
    //         + '&grade=' + this.selectArr.grade + numberName)
    //
    // },
  },
};
</script>

<style lang="scss" scoped>
.content-flex-pages {
  .black {
    color: #4bb3ff;
  }

  .greyColor {
    color: #ccc;
  }
}

.table-pagination {
  text-align: right;
  margin-top: 10px;
  /deep/ span {
    float: left;
  }
}

.child-top {
  .input-from {
    float: left;
    min-width: 850px;

    & > .el-select {
      width: 150px;
      float: left;
      margin-top: 10px;

      .el-input__inner {
        border-radius: 0;
        border-right: transparent;
      }

      .el-input__inner:focus {
        border-right: 1px solid #409eff;
      }
    }

    .el-select:nth-child(4) {
      .el-input__inner:focus {
        border-right: 0.052083rem solid #409eff;
      }
    }

    .el-select:nth-child(5) {
      .el-input__inner {
        border-right: 0;
      }

      .el-input__inner:focus {
        border-right: 0.052083rem solid #409eff;
      }
    }

    .el-select:nth-child(6) {
      width: 120px;
      margin-left: 10px;

      .el-input__inner {
        border-radius: 0px 4px 4px 0;
      }
    }

    .el-select:nth-child(1) {
      .el-input__inner {
        border-radius: 4px 0 0 4px;
      }
    }

    .el-input.el-input--suffix.left-child-input {
      float: left;
      width: 200px;

      .el-input__inner {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .operation-button {
    // float: left;

    .el-button {
      margin-right: 0;
    }
  }
}

.el-input-group__append {
  .el-button.el-button--default {
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ModifyDoalog {
  margin-top: -20px;
  padding: 0 0 0 20px;

  & > p {
    font-size: 20px;
    color: #3b3b3b;
    text-align: center;
    margin-bottom: 20px;
  }

  .el-form {
    padding-right: 30px;
  }
}

.el-select {
  width: 100%;
}
</style>
